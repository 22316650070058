/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllCompanies, getChallengeDetails } from "../../../redux/actions/challengeActions";
import { withTranslation } from "react-i18next";
import { getSurveyLibraries } from "../../../redux/actions";
import { getCompanyLocation } from "../../../redux/actions";
import EditChallenge from './editChallenge';
import Waiting from '../../Waiting';
import _ from 'lodash';
class EditActivityChallenge extends Component {
  constructor() {
    super();
    this.state = {
      editLibrary:''
    }
  }  

  componentDidMount() {
    const { getChallengeDetails, history, fetchAllCompanies, getSurveyLibraries, getCompanyLocation } = this.props;
    const StringArray = history.location.pathname.split('/');
    getSurveyLibraries();
    fetchAllCompanies();
    getCompanyLocation(this.props.companyDetails.id);
    if (StringArray[(StringArray.length - 1)] != '') {
      getChallengeDetails(StringArray[(StringArray.length - 1)]);
    } else {
      getChallengeDetails(StringArray[(StringArray.length - 2)]);
    }
  }

  componentDidUpdate(prevProps) {
    const { history, getChallengeDetails } = this.props;
    const StringArray = history.location.pathname.split('/');
    if (history.location.pathname !== prevProps.history.location.pathname) {
      if (StringArray[(StringArray.length - 1)] != '') {
        getChallengeDetails(StringArray[(StringArray.length - 1)]);
      } else {
        getChallengeDetails(StringArray[(StringArray.length - 2)]);
      }
    }
  }
 
  render() {
    const { challengeDetails, role, history } = this.props;
    if (!challengeDetails||_.isEmpty(challengeDetails)) {
      return <Waiting />
    }
    const isSurvivorChallenge = challengeDetails && challengeDetails['challenge_type'] === 'survivor';
    const isGroupChallenge = challengeDetails && challengeDetails['challenge_type'] === 'new_group';
    return (
      <EditChallenge
        isSurvivorChallenge={isSurvivorChallenge}
        isGroupChallenge = {isGroupChallenge}
        history={history}
        survivorChallengeData={challengeDetails}
        role={role}
        activityChallengeById={true}
        editLibrary={false}
      />
    )
  }
}

EditActivityChallenge.propTypes = {
  isSurvivorChallenge: PropTypes.bool.isRequired,
  createChallenge: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onHide: PropTypes.func,
  MandatoryFields: PropTypes.array.isRequired,
  survivorChallengeData: PropTypes.object,
  allCompanies: PropTypes.array,
  fetchAllCompanies: PropTypes.func.isRequired,
  role: PropTypes.string,
  fetchCompanyForUser: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  locationDetails: PropTypes.array,
  companyDetails: PropTypes.object,
  isGroupChallenge: PropTypes.bool,
  activityChallengeById: PropTypes.bool,
  createLibrary: PropTypes.bool,
  editLibrary: PropTypes.bool,
  userPermissions: PropTypes.array,
  surveyLibraries: PropTypes.array,
  getSurveyLibraries: PropTypes.func,
  t: PropTypes.func,
  challengeDetails: PropTypes.array,
  getChallengeDetails: PropTypes.func,
  getCompanyLocation: PropTypes.func
};

const mapStateToProps = (state) => ({
  allCompanies: state.challenges.allCompanies,
  userCompany: state.profileData.userCompany,
  locationDetails: state.register.locationDetails,
  companyDetails: state.profileData.companyDetails,
  userPermissions: state.profileData.userPermissions,
  surveyLibraries: state.surveys.surveyLibraries,
  challengeDetails: state.challenges.challengeDetails
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllCompanies: () => dispatch(getAllCompanies()),
  getSurveyLibraries: () => dispatch(getSurveyLibraries()),
  getChallengeDetails: (id) => dispatch(getChallengeDetails(id)),
  getCompanyLocation: (id) => dispatch(getCompanyLocation(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditActivityChallenge));
